import { PAGE_PATH } from "../../contexts/application/constants"

export const MaxFileNameLength = 100

export const Max2FACodeLength = 6

export const CoopCompanyName = "Co-op Legal Services"

export const isCurrentRouteDocumentLibraryPage =
  window.location.pathname === PAGE_PATH.AccountDocumentLibraryPage

export const DocumentRequestNameMaxLength = 50
